import React, { useEffect } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Button,
  IconButton,
  Theme,
} from "@material-ui/core";
import { CSVLink} from "react-csv";
import { ToolHeader } from "../components/dashboard/toolHeader";
import { ToolDisposalFilterModule } from "./toolDisposalFilterModule";
import { ToolDisposalGrid } from "./toolDisposalGrid";
import { SpeedDialComponent as SpeedDial } from "../components/reusable/speedDial";
import PrintIcon from "@material-ui/icons/Print";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch } from "react-redux";
import moment from "moment";
import { getToolDisposalAction } from "./toolDisposalSlice";
import { useAppData } from "./../services/hooks";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { DashboardWalkthrough as Walkthrough } from "./walkthrough";

export const ToolDisposalPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filters = useAppData((r) => r.toolDisposalSlice.filters);
  const toolDisposalValue = useAppData(
    (r) => r.toolDisposalSlice.toolDisposals.value
  );
  const requestorValue = useAppData((r) =>
    r &&
    r.toolDisposalSlice &&
    r.toolDisposalSlice.requestorInfo &&
    r.toolDisposalSlice.requestorInfo.requestorValue
      ? r.toolDisposalSlice.requestorInfo.requestorValue
      : ""
  );
  const requestorValueMail = useAppData(
    (r) =>
      r &&
      r.toolDisposalSlice &&
      r.toolDisposalSlice.requestorInfo &&
      r.toolDisposalSlice.requestorInfo.requestorValue &&
      r.toolDisposalSlice.requestorInfo.requestorValue.mail
  );
  const header = "TOOL DISPOSAL REQUESTS";
  let csvData = toolDisposalValue.map((toolDisposalValue: any) => ({
    Status:
      toolDisposalValue.disposalStatusId === "1"
        ? "Draft"
        : toolDisposalValue.disposalStatusId === "2"
        ? "InProcess"
        : toolDisposalValue.disposalStatusId === "3"
        ? "Approved"
        : toolDisposalValue.disposalStatusId === "4"
        ? "Reject"
        : "Draft",
    "Part Number":
      toolDisposalValue.partDetails.partDetail[0].partNumber != null
        ? toolDisposalValue.partDetails.partDetail[0].partNumber
        : "-",
    "Requested By":
      toolDisposalValue.requester != null ? toolDisposalValue.requester : "-",
    "Tool Number - Description":
      toolDisposalValue.toolNumber + "-" + toolDisposalValue.toolDescription,
    "My Approval Status":
      toolDisposalValue.myApprovalStatus === null ||
      toolDisposalValue.myApprovalStatus === ""
        ? "-"
        : toolDisposalValue.myApprovalStatus,
    "Request ID":
      toolDisposalValue.requestId != null ? toolDisposalValue.requestId : "-",
    "Started / Last Updated":
      toolDisposalValue.lastUpdatedDateTime != null
        ? moment(toolDisposalValue.lastUpdatedDateTime)
            .utc()
            .format("YYYY-MM-DD")
        : "-",
    "Due / Completed": toolDisposalValue.completedDate
      ? moment(toolDisposalValue.completedDate).utc().format("YYYY-MM-DD")
      : "-",
  }));

  useEffect(() => {
    if (requestorValue.mail != undefined) {
      dispatch(getToolDisposalAction());
    }
  }, [filters]);

  useEffect(() => {
    if (requestorValueMail === undefined) {
    }
  }, [requestorValueMail]);

  return (
    <>
      <Walkthrough />
      <ToastContainer />
      <Grid container direction={"column"}>
        <Grid item className={classes.headerButtonContainer}>
          <Grid item className={classes.gridHeader}>
            <ToolHeader header={header} />
          </Grid>
          <Grid item className={classes.buttonWrapper}>
            <Grid item className={classes.buttonContainer}>
              <Link to={{ pathname: "/disposal-request" }}>
                <Button
                  className={classes.button}
                  data-tut={"reactour__newRequestButton"}
                  variant="contained"
                  color="primary"
                  disabled={false}
                  startIcon={<AddIcon />}
                >
                  New Request
                </Button>
              </Link>
            </Grid>

            <Grid item>
              <CSVLink filename={"tooling_disposal.csv"} data={csvData}>
                <IconButton
                  data-tut={"reactour__downloadButton"}
                  aria-label={"print"}
                  color={"primary"}
                >
                  <CloudDownloadIcon />
                </IconButton>
              </CSVLink>
            </Grid>

            <Grid item>
              <IconButton
                data-tut={"reactour__printerIcon"}
                aria-label={"print"}
                color={"primary"}
                onClick={() => window.print()}
              >
                <PrintIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction={"row"}>
        <Grid className={classes.filterContainer} item xs={12} md={3} lg={2}>
          <ToolDisposalFilterModule toolDisposalData={toolDisposalValue} />
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <Grid
            container
            direction={"row"}
            className={classes.loadingContainer}
            justifyContent={"center"}
          >
            <Grid item>
              <ToolDisposalGrid toolDisposalData={toolDisposalValue} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"flex-end"}
        className={classes.linkContainer}
      >
        <SpeedDial className={classes.fab} />
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      minWidth: "7.188rem",
    },
    buttonContainer: {
      padding: theme.spacing(0, 0.5),
    },
    buttonWrapper: {
      alignItems: "center",
      display: "flex",
    },
    headerButtonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(1),
    },
    fab: {
      margin: 0,
      top: "auto",
      right: 20,
      bottom: 20,
      left: "auto",
      position: "fixed",
    },
    linkContainer: {
      marginTop: theme.spacing(1),
    },
    loadingContainer: {
      width: "inherit",
    },
    loadingIconContainer: {
      textAlign: "center",
    },
    circularProgress: {
      zIndex: 9,
      position: "absolute",
      margin: theme.spacing(12, 0),
      display: "flex",
    },
    gridHeader: {
      padding: theme.spacing(0),
    },
    filterContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);
