import React, { useEffect } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Button,
  IconButton,
  Theme,
  Box,
} from "@material-ui/core";
import { ToolHeader } from "../../components/dashboard/toolHeader";
import { LeftSection } from "./newRequestLeftSection";
import { RightSection } from "./newRequestRightSection";
import { BottomSection } from "./newRequestBottomSection";
import { useDispatch } from "react-redux";
import { ConditionModel } from "./newRequestConditionModel";
import { ApprovalNotesModel } from "./newRequestApprovalNotesModel";
import { useAppData } from "../../services/hooks";
import { SpeedDialComponent as SpeedDial } from "../../components/reusable/speedDial";
import PrintIcon from "@material-ui/icons/Print";
import ContentCopyIcon from "@material-ui/icons/FileCopy";
import SaveIcon from "@material-ui/icons/Save";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { useLocation } from "react-router-dom";
import { ItoolDisposalFilterset } from "../../services/generated/ApiClientGenerated";
import { DashboardWalkthrough as Walkthrough } from "./walkthrough";
import { Link } from "react-router-dom";
import {
  submitDataAction,
  getNewRequest_toolTypesAction,
  getNewRequest_productLinesAction,
  updateDisposalRequestAction,
  requestorAction,
  setToolDisposalFiltersAction,
  id_stateAction,
  approve_put_Action,
} from "../toolDisposalSlice";
export const NewRequestPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [checkStatus, setCheckStatus] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [modalActive, setModalActive] = React.useState(false);
  const [requesterData, setRequesterData] = React.useState(false);
  const [statusSubmit, setStatusSubmit] = React.useState(false);
  const requestorValue = useAppData((r) =>
    r &&
    r.toolDisposalSlice &&
    r.toolDisposalSlice.requestorInfo &&
    r.toolDisposalSlice.requestorInfo.requestorValue
      ? r.toolDisposalSlice.requestorInfo.requestorValue
      : ""
  );
  const isSaveSubmitLoader = useAppData(
    (r) => r.toolDisposalSlice.isSaveSubmitLoader
  );
  const approveRejectResponse = useAppData(
    (r) => r.toolDisposalSlice.approveRejectResponse
  );
  const row = useAppData((r) => r.toolDisposalSlice.updateValues.value);
  let paramsValue = window.location.pathname.split("/")[2];
  const header = "REQUEST FOR TOOL DISPOSAL";

  const onClick = () => {
    if (checkStatus) {
      setCheckStatus(false);
    } else {
      setCheckStatus(true);
    }
  };
  const handleClose = () => {
    setModalActive(false);
    setModalOpen(false);
  };

  const handleConfirm = () => {
    setModalOpen(false);
    if (
      row &&
      row.attachments &&
      row.attachments.attachment &&
      row.attachments.attachment[0].fileAbsoluteUrl.match("temprequestid")
    ) {
      setStatusSubmit(true);
      let updatedRow = { ...row, disposalStatusId: "1" };
      dispatch(updateDisposalRequestAction(updatedRow));
    } else {
      let updatedRow = { ...row, disposalStatusId: "2" };
      dispatch(updateDisposalRequestAction(updatedRow));
    }
    dispatch(isSaveSubmitLoader(true));
  };
  useEffect(() => {
    setModalOpen(true);
    dispatch(getNewRequest_productLinesAction());
    dispatch(getNewRequest_toolTypesAction());
    dispatch(requestorAction());
  }, []);

  const onClickSave = () => {
    let updatedRow = { ...row, disposalStatusId: "1" };
    dispatch(updateDisposalRequestAction(updatedRow));
  };

  const onClickApproveAndReject = (IsApproved) => {
    let sourcingId = window.location.origin.match("dev")
      ? "DEVTOOLSAPPROVALDB"
      : window.location.origin.match("stg")
      ? "STGTOOLSAPPROVALDB"
      : "PRDTOOLSAPPROVALDB";

    let approvalRow = {
      RequestId: row.requestId,
      EmailAddress: requestorValue.mail,
      IsApproved: IsApproved,
      Comments: comment,
      LastUpdatedDateTime: row.lastUpdatedDateTime,
      LastUpdatedUser: row.lastUpdatedUser,
      SourceSystemId: sourcingId,
    };

    dispatch(approve_put_Action(approvalRow));
  };

  const onClickSubmit = () => {
    setModalOpen(true);
    setModalActive(true);
  };

  useEffect(() => {
    let rowData = { ...row };
    delete rowData.requestId;
    delete rowData.attachments;
    // delete rowData.approverManagers
    {
      (location.pathname === "/disposal-request/" ||
        location.pathname === "/disposal-request" ||
        location === undefined) &&
        dispatch(
          submitDataAction({
            body: {
              ...rowData,
              requester: requestorValue.mail,
              lastUpdatedUser: requestorValue.displayName,
              disposalStatusId: "",
            },
          })
        );
    }
  }, [location, requestorValue]);
  function handleFilterChange<TKey extends keyof ItoolDisposalFilterset>(
    key: TKey,
    value: ItoolDisposalFilterset[TKey]
  ) {
    id_stateAction([]);

    dispatch(setToolDisposalFiltersAction({ key, value }));
  }

  useEffect(() => {
    if (paramsValue != "") {
      {
        paramsValue != undefined &&
          handleFilterChange("RequestId", paramsValue);
      }
    }
  }, [paramsValue, approveRejectResponse]);

  useEffect(() => {
    let currentRequester =
      row && requestorValue && row.requester === requestorValue.mail;
    setRequesterData(currentRequester);
  }, [
    row && row.disposalStatusId != "",
    row && row.disposalStatusId != undefined,
  ]);
  const approverExistenceCheck =
    (row.disposalStatusId === "2" ||
      row.disposalStatusId === "3" ||
      row.disposalStatusId === "4") &&
    requestorValue &&
    row &&
    row.approverManagers &&
    row.approverManagers.approverManager.filter(
      (o) => o.email === requestorValue.mail
    );
  const approverAlreadyApproved =
    (row.disposalStatusId === "2" ||
      row.disposalStatusId === "3" ||
      row.disposalStatusId === "4") &&
    requestorValue &&
    row &&
    row.approverManagers &&
    row.approverManagers.approverManager.filter((o) => {
      return o.email === requestorValue.mail;
    });

  return (
    <>
      <Walkthrough />
      {modalActive && (
        <ConditionModel
          open={modalOpen}
          handleClose={handleClose}
          onClick={onClick}
          checkStatus={checkStatus}
          handleConfirm={handleConfirm}
        />
      )}
      <Grid container direction={"column"}>
        <Grid item>
          <Link
            to={{ pathname: "/tool-disposal" }}
            style={{ color: "#004e97" }}
          >
            Tool Disposal
          </Link>
          <span style={{ color: "#004e97" }}>{" > "}</span>
          <Link
            to={{ pathname: "/disposal-request" }}
            style={{ color: "#004e97" }}
          >
            Request
          </Link>
        </Grid>
        <Grid item className={classes.headerButtonContainer}>
          <Grid item className={classes.gridHeader}>
            <ToolHeader header={header} />
          </Grid>
          <Grid item className={classes.buttonWrapper}>
            {(row.disposalStatusId === "1" ||
              row.disposalStatusId === "" ||
              row.disposalStatusId === null) && (
              <>
                <Grid item className={classes.buttonContainer}>
                  <Button
                    className={classes.button}
                    data-tut={"reactour__Submit"}
                    variant="contained"
                    color="primary"
                    disabled={
                      row.projectNumber &&
                      row.productLines &&
                      row.modelYearMin &&
                      row.modelYearMax &&
                      row.lastUsedDate &&
                      row.totalPOValue &&
                      row.totalPOValue != "0" &&
                      row.totalPOValue != "0.0" &&
                      row.totalPOValue != "0.00" &&
                      row.totalPOValue != "0.000" &&
                      row.totalPOValue != "0.0000" &&
                      row.totalPOValue != "0.00000" &&
                      row.supplierName &&
                      row.supplierNumber &&
                      row.countryToolResidesIn &&
                      row.reasonForRequest &&
                      row.estToolScrapValue &&
                      row.partyResponsibleForDisposition &&
                      row.willPolarisReceiveCredit &&
                      row.explainationForScrapAggrement &&
                      row.approverManagers &&
                      row.businessUnit &&
                      row.toolNumber &&
                      row.reasonForRequest &&
                      row.poNumber
                        ? false
                        : true
                    }
                    onClick={onClickSubmit}
                    startIcon={<SaveAltIcon />}
                  >
                    SUBMIT
                  </Button>
                </Grid>

                <Grid item className={classes.buttonContainer}>
                  <Button
                    className={classes.button}
                    data-tut={"reactour__save"}
                    variant="contained"
                    color="primary"
                    disabled={
                      row.businessUnit && row.toolNumber && row.reasonForRequest
                        ? false
                        : true
                    }
                    onClick={onClickSave}
                    startIcon={<SaveIcon />}
                  >
                    SAVE
                  </Button>
                </Grid>
              </>
            )}
            {row.disposalStatusId === "2" &&
              approverExistenceCheck != undefined &&
              approverExistenceCheck.length > 0 && (
                <>
                  <Grid item className={classes.buttonContainer}>
                    <ApprovalNotesModel
                      title={"REJECT"}
                      setComment={setComment}
                      disabled={
                        (approverAlreadyApproved &&
                          approverAlreadyApproved[0] &&
                          (approverAlreadyApproved[0].disposalStatusId ===
                            "3" ||
                            approverAlreadyApproved[0].disposalStatusId ===
                              "4")) ||
                        requesterData ||
                        row.disposalStatusId === "3" ||
                        row.disposalStatusId === "4"
                          ? true
                          : false
                      }
                      startIcon={<ClearIcon />}
                      onClickConfirmButton={() =>
                        onClickApproveAndReject("false")
                      }
                    />
                  </Grid>

                  <Grid item className={classes.buttonContainer}>
                    <ApprovalNotesModel
                      title={"APPROVE"}
                      setComment={setComment}
                      disabled={
                        (approverAlreadyApproved &&
                          approverAlreadyApproved[0] &&
                          (approverAlreadyApproved[0].disposalStatusId ===
                            "3" ||
                            approverAlreadyApproved[0].disposalStatusId ===
                              "4")) ||
                        requesterData ||
                        row.disposalStatusId === "3" ||
                        row.disposalStatusId === "4"
                          ? true
                          : false
                      }
                      startIcon={<CheckIcon />}
                      onClickConfirmButton={() =>
                        onClickApproveAndReject("true")
                      }
                    />
                  </Grid>
                </>
              )}
            {row.disposalStatusId === "2" &&
              approverExistenceCheck != undefined &&
              approverExistenceCheck.length < 1 && (
                <>
                  <Grid item className={classes.buttonContainer}>
                    <ApprovalNotesModel
                      title={"You are not in Approvers List"}
                      setComment={setComment}
                      disabled={true}
                      startIcon={<SaveIcon />}
                      onClickConfirmButton={() =>
                        onClickApproveAndReject("true")
                      }
                    />
                  </Grid>
                </>
              )}
            {row.disposalStatusId === "4" && (
              <>
                <Grid item className={classes.buttonContainer}>
                  <ApprovalNotesModel
                    title={"ALREADY REJECTED"}
                    setComment={setComment}
                    disabled={
                      row.disposalStatusId === "3" ||
                      row.disposalStatusId === "4"
                        ? true
                        : false
                    }
                    startIcon={<SaveIcon />}
                    onClickConfirmButton={() => onClickApproveAndReject("true")}
                  />
                </Grid>
              </>
            )}
            {row.disposalStatusId === "3" && (
              <>
                <Grid item className={classes.buttonContainer}>
                  <ApprovalNotesModel
                    title={"ALREADY APPROVED"}
                    setComment={setComment}
                    disabled={
                      row.disposalStatusId === "3" ||
                      row.disposalStatusId === "4"
                        ? true
                        : false
                    }
                    startIcon={<SaveIcon />}
                    onClickConfirmButton={() => onClickApproveAndReject("true")}
                  />
                </Grid>
              </>
            )}
            <Grid item>
              <IconButton
                data-tut={"reactour__printerIcon"}
                aria-label={"print"}
                color={"primary"}
                onClick={() => window.print()}
              >
                <PrintIcon />
              </IconButton>
            </Grid>
            {row.disposalStatusId != "" && (
              <Link to={{ pathname: "/disposal-request/" }}>
                <Grid item>
                  <IconButton
                    data-tut={"reactour__ContentCopy"}
                    aria-label={"print"}
                    color={"primary"}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Grid>
              </Link>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction={"row"} spacing={3}>
        <Grid className={classes.filterContainer} item xs={12} md={4} lg={4}>
          <LeftSection
            statusSubmit={statusSubmit}
            paramsData={row}
            requester={requesterData}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <RightSection paramsData={row} requester={requesterData} />
        </Grid>
      </Grid>
      {(row.disposalStatusId === "3" ||
        row.disposalStatusId === "2" ||
        row.disposalStatusId === "4") && (
        <Box mt={3}>
          <BottomSection paramsData={row} requester={requesterData} />
        </Box>
      )}
      <Grid
        container
        justifyContent={"flex-end"}
        className={classes.linkContainer}
      >
        <SpeedDial className={classes.fab} />
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      minWidth: "7.188rem",
    },
    buttonContainer: {
      padding: theme.spacing(0, 0.5),
    },
    buttonWrapper: {
      alignItems: "center",
      display: "flex",
    },
    headerButtonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(1),
    },

    fab: {
      margin: 0,
      top: "auto",
      right: 20,
      bottom: 20,
      left: "auto",
      position: "fixed",
    },
    linkContainer: {
      marginTop: theme.spacing(1),
    },
    loadingContainer: {
      backgroundColor: "green",

      width: "inherit",
    },
    loadingIconContainer: {
      textAlign: "center",
    },
    circularProgress: {
      zIndex: 9,
      position: "absolute",
      margin: theme.spacing(12, 0),
      display: "flex",
    },
    gridHeader: {
      padding: theme.spacing(0),
    },
    filterContainer: {
      //backgroundColor:"red",
      marginBottom: theme.spacing(2),
    },
    // [`@media print`]: {
    //   buttonWrapper: {
    //     display: "none",
    //   },
    //   filterContainer: {
    //     display: "none",
    //   },
    // },
  })
);
